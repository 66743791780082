"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const cloudinary_core_1 = require("cloudinary-core");
exports.default = {
    install: (Vue) => {
        const cl = new cloudinary_core_1.Cloudinary({
            cloud_name: process.env.VUE_APP_CLOUDINARY_NAME,
        });
        Vue.prototype.$cloudinary = cl;
    },
};
