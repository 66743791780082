<template>
  <UserNotifications :notifications="notifications" />
</template>

<script>
import { mapState, mapActions } from 'vuex';

import UserNotifications from './UserNotifications.vue';

import { useUserNotifiedSubscription } from './UserNotificationsContainer.generated';

export default {
  name: 'UserNotificationsContainer',
  components: {
    UserNotifications,
  },
  computed: {
    ...mapState('messages', {
      notifications: 'alerts',
    }),
  },
  apollo: {
    $subscribe: {
      userNotified: useUserNotifiedSubscription({
        result({ data }) {
          const { id, status, message } = data.userNotified;

          this.pushFlashMessage({
            id,
            message,
            colorClass: this.convertNotificationStatusToColorClass(status),
          });

          // No need to use the GraphQL cache to manage the notifications
          return data.userNotified;
        },
        // Pauses the subscription if the user is logging in or is not logged in.
        skip() {
          return !this.$auth.loggedUser;
        },
      }),
    },
  },
  methods: {
    ...mapActions('messages', ['pushFlashMessage']),
    convertNotificationStatusToColorClass(status) {
      try {
        const statusToColorClassMap = {
          INFO: 'info',
          WARNING: 'warning',
          SUCCESS: 'valid',
          FAILURE: 'invalid',
        };

        if (!statusToColorClassMap[status]) {
          throw new Error(`Invalid param value [status='${status}']`);
        }

        return statusToColorClassMap[status];
      } catch (err) {
        this.$sentry.captureException(err);

        return 'info';
      }
    },
  },
};
</script>
