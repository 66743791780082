import VTooltip, { VPopover } from 'v-tooltip';

VPopover.props.container.default = false;

/**
 * Ensure https://github.com/Akryum/v-tooltip to be initialized by the Vue instance of UI Admin or UI App.
 *
 * Required by (non-exhaustive list):
 * - FreelanceResumeUpdload
 */
export default {
  install: Vue => {
    Vue.component('VPopover', VPopover);
    Vue.use(VTooltip);
  },
};
