/* eslint-disable no-param-reassign */
import * as SentryBrowser from '@sentry/browser';
import * as Integrations from '@sentry/integrations';

/**
 *
 * @param {Object} p
 * @param {string} p.project {'ui-admin', 'ui-app'}.
 * @param {string} p.version Value of 'project' from package.json
 * @param {string} p.environment {'production', 'staging', 'testing', 'development'}
 * @param {string} p.dsn Sentry's Data Source Name: configuration required by the Sentry SDKs as a standard URL
 * including the protocol, public key, the server address, and the project identifier.
 * @param {Object} p.Vue The VueJS constructor.
 */
const initSentryBrowser = ({ project, version, environment, dsn, Vue }) => {
  SentryBrowser.init({
    dsn,
    environment,
    integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true })],
    release: `${project}@${version}`,
    beforeSend: (event, hint) => {
      // Prevents from logging 'undefined' on development env
      if (environment !== 'production' && (hint.originalException || hint.syntheticException)) {
        // eslint-disable-next-line no-console
        console.error(hint.originalException || hint.syntheticException);
      }
      return event;
    },
  });

  return SentryBrowser;
};

export { initSentryBrowser };
