// TODO Delete this or merge this with @frk/logger (commons/api/utils/logger) ??
/* eslint-disable no-console */
if (!process.env.VUE_APP_DEBUG) {
  console.debug = () => {};
  console.info = () => {};
  console.warn = () => {};
  console.error = () => {};
}

const logger = {
  d: console.debug.bind(console),
  i: console.info.bind(console),
  w: console.warn.bind(console),
  e: console.error.bind(console),
};

export default {
  install: Vue => {
    /* eslint-disable */
    Vue.$logger = logger;
    Vue.prototype.$logger = logger;
    /* eslint-enable */
  },
};
