import Vue from 'vue';
import VueRouter, { NavigationGuardNext, Route } from 'vue-router';

import { getInstance, VueAuth0InstanceType } from './plugins/auth';

Vue.use(VueRouter);

export function watchAuthLoading(
  next: NavigationGuardNext<Vue>,
  guardCallback: (instance: VueAuth0InstanceType) => void
) {
  const $auth = getInstance();

  // If loading has already finished, check our auth state using `guard()`
  if (!$auth.loading) {
    return guardCallback($auth);
  }

  // Watch for the loading property to change before we check loggedUser
  $auth.$watch('loading', (loading: boolean) => {
    if (loading === false) {
      return guardCallback($auth);
    }

    next(false);
  });
}

export async function isAuthenticated(to: Route, from: Route, next: NavigationGuardNext<Vue>) {
  watchAuthLoading(next, async $auth => {
    // If the user is authenticated, continue with the route
    if ($auth.loggedUser) {
      return next();
    }

    // Otherwise, log in with a redirect back to the visited page
    $auth.loginWithRedirect({ appState: { targetUrl: to.fullPath }, mode: 'login' });
    next(false);
  });
}

export default VueRouter;
export * from 'vue-router';
