<template>
  <div
    v-if="active"
    class="tw-relative tw-flex tw-max-h-screen tw-w-full tw-flex-col tw-items-center tw-justify-center tw-overflow-hidden"
    :class="mode === 'block' ? 'tw-h-[5rem]' : 'tw-min-h-screen'"
  >
    <div
      class="tw-absolute tw-text-center"
      v-if="mode === 'cover' || mode === 'route'"
    >
      <img
        class="tw-block tw-h-[3rem]"
        src="../assets/images/logo/logo_frk.svg"
      />
      <i class="fa-solid fa-circle-notch fa-spin tw-mt-12 tw-text-3xl tw-text-primary"></i>
    </div>
    <div
      v-else
      class="tw-absolute tw-text-center"
    >
      <i class="fa-solid fa-circle-notch fa-spin tw-mb-2 tw-text-xl tw-text-gray-400"></i>
      <h3 class="tw-text-sm tw-text-gray-400">En cours de chargement...</h3>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    mode: {
      type: String,
      default: 'route',
      validator: value => ['route', 'block', 'cover'].includes(value),
    },
    background: {
      type: String,
      default: 'tw-bg-gray-50 dark:tw-bg-slate-900',
    },
  },
};
</script>
