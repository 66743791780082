
import Vue from 'vue';

import { hasRoles } from '@frk/models-core/Role.core';
import { Role } from '@frk/graphql-types';

import logo from '@frk/commons/ui/assets/images/logo/logo_k.svg';
import FrkSidebarItem from './FrkSidebarItem.vue';

export default Vue.extend({
  name: 'FrkSidebar',
  components: {
    FrkSidebarItem,
  },
  data() {
    return {
      logo,
      roleIcons: {
        candidat: 'fa-address-book',
        client: 'fa-building',
      },
    };
  },
  computed: {
    canSeeContractSettings(): boolean {
      return hasRoles(this.$auth.loggedUser, [Role.Admin, Role.Tech, Role.Commercial]);
    },
  },
});
