import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    activeForms: {},
  },
  getters: {
    isAnyEditedForm(state) {
      return Object.values(state.activeForms).filter(({ formState }) => formState.value !== 'idle').length > 0;
    },
  },
  actions: {
    /**
     * @param commit
     * @param {String} formName
     * @param {Boolean} inEdition
     */
    storeFormState({ commit }, { formName, state: formState }) {
      commit('setFormState', { formName, formState });
    },
    deleteFormState({ commit }, formName) {
      commit('deleteFormState', formName);
    },
  },
  mutations: {
    setFormState(state, { formName, formState }) {
      // Store only the attribute 'value' of the form state.
      // If any other data is useful in the future, we could easily add it by updating this code.
      const { value } = formState;
      const partialFormState = { value };

      // See: https://vuex.vuejs.org/guide/mutations.html#mutations-follow-vue-s-reactivity-rules
      Vue.set(state.activeForms, formName, { formState: partialFormState });
    },
    deleteFormState(state, formName) {
      if (state.activeForms[formName]) {
        delete state.activeForms[formName];
      }
    },
  },
};
