import VuexPersist from 'vuex-persist';

const buildVuexStoreParams = (vuexModules, vuexPersistModules) => {
  const ignoredMutations = ['auth/setLock'];
  const vuexLocal = new VuexPersist({
    modules: vuexPersistModules,
    filter: mutation => !ignoredMutations.includes(mutation.type),
    supportCircular: true,
  });

  return {
    modules: vuexModules,
    plugins: [vuexLocal.plugin],
  };
};

export const initVuexStore = (Vuex, vuexModules, vuexPersistModules, retry = 2) => {
  if (retry <= 0) {
    throw new Error('initVuexStore() — Max retry reached');
  }

  try {
    const { modules, plugins } = buildVuexStoreParams(vuexModules, vuexPersistModules);

    return new Vuex.Store({ modules, plugins });
  } catch (err) {
    // Since we move from JSON to flatted to manage circular dependencies, the serialized object
    // stored in localStorage could be corrupted (object instead of array).
    window.localStorage.removeItem('vuex');

    return initVuexStore(Vuex, vuexModules, vuexPersistModules, retry - 1);
  }
};
