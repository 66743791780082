/* eslint-disable no-param-reassign,no-underscore-dangle,no-console */

// https://github.com/LinusBorg/vue-directive-clickout/blob/develop/src/index.js

function isVisible(el) {
  // On e2e tests, isVisible can be called when window is null
  const { display, visibility } = window ? window.getComputedStyle(el) : {};
  return display !== 'none' && visibility !== 'hidden';
}

const directive = {
  bind(el, binding, vNode) {
    // Provided expression must evaluate to a function.

    if (process.env.NODE_ENV !== 'production' && typeof binding.value !== 'function') {
      const compName = vNode.context.name;
      let warn = `[Vue-clickout:] provided expression '${binding.expression}' is not a function, but has to be`;
      if (compName) {
        warn += `Found in component '${compName}'`;
      }

      console.warn(warn);
    }

    // Define Handler and cache it on the element
    const bubbleMod = binding.modifiers.bubble;
    const visibleMod = binding.modifiers.visible;
    const handler = e => {
      const targetOk = bubbleMod || (!el.contains(e.target) && el !== e.target);
      const visibilityOk = !visibleMod || isVisible(el);
      if (targetOk && visibilityOk) {
        binding.value(e);
      }
    };
    el.__vueClickout__ = handler;

    // add Event Listeners
    document.addEventListener('click', handler);
  },

  unbind(el) {
    // Remove Event Listeners
    document.removeEventListener('click', el.__vueClickOutside__);
    el.__vueClickOutside__ = null;
  },
};

if (window && window.Vue) {
  window.Vue.directive('clickout', directive);
}

export { directive as default };
